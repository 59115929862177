class User {
    static localSet(data) {
        localStorage.setItem('user', JSON.stringify({ id: data.id, userName: data.userName }));
    }

    static localGet() {
        const val = localStorage.getItem('user');
        return val ? JSON.parse(val) : null;
    }

    static checkIntented(phone) {
        return new Promise((resolve, reject) => {
            $.ajax({
                method: 'GET',
                url: `/api/intended?telephone=${phone}`,
            })
                .done((data) => {
                    if (data) {
                        this.localSet(data);
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                })
                .fail((err) => {
                    reject(err);
                });
        });
    }
}

export default User;
