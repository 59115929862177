import $ from 'jQuery';

$(() => {
    // menu click event
    $('.menu-btn').click(function () {
        $(this).toggleClass('act');
        if ($(this).hasClass('act')) {
            $('nav').addClass('act');
        } else {
            $('nav').removeClass('act');
        }
    });
});
